<template>
  <input
    :id="id"
    :class="inputClasses"
    :type="type"
    :value="localValue"
    :placeholder="placeholder"
    :required="required"
    :disabled="disabled"
    :readonly="readonly"
    @keyup="$emit('keyup', $event)"
    @input="handleInput"
    @blur="handleBlur"
    @click="$emit('click', $event)"
    @focus="$emit('focus', $event)"
  >
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'update',
  },

  props: {
    id: {
      type: String,
      default: '',
    },

    value: {
      type: [String, Number],
      default: '',
    },

    type: {
      type: String,
      default: 'text',
    },

    placeholder: {
      type: String,
      default: '',
    },

    trim: {
      type: Boolean,
      default: false,
    },

    required: {
      type: Boolean,
      default: false,
    },

    small: {
      type: Boolean,
      default: false,
    },

    state: {
      type: Boolean,
      default: null,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    variant: {
      type: String,
      default: 'light',

      validator(value) {
        return ['light', 'primary'].includes(value)
      },
    },

    icon: {
      type: String,
      default: null,

      validator(value) {
        return [null, 'search', 'lock'].includes(value)
      },
    },

    formatter: {
      type: Function,
      default: (val) => val,
    },

    noFocusOutline: {
      type: Boolean,
      default: false,
    },

    modelValue: {
      type: String,
      default: '',
    },
  },

  emits: ['update', 'input', 'blur', 'click', 'focus', 'keyup', 'update:modelValue'],

  data() {
    return {
      localValue: this.value,
    }
  },

  computed: {
    inputClasses() {
      return [
        'zform-control',
        `zform-control-${this.variant}`,
        this.icon && `zform-control-icon-${this.icon}`,
        {
          'zform-control-small': this.small,
          'zform-control-invalid': this.state === false,
          'zform-control-no-focus-outline': this.noFocusOutline,
        },
      ]
    },
  },

  watch: {
    value(val) {
      this.localValue = this.formatValue(val)
    },
  },

  methods: {
    formatValue(val) {
      const sValue = val.toString()
      return this.formatter(sValue)
    },

    modifyValue(val) {
      const sValue = val.toString()
      if (this.trim) {
        return sValue.trim()
      }
      return sValue
    },

    handleInput(event) {
      const { value } = event.target
      const formattedValue = this.formatValue(value)
      this.localValue = formattedValue

      const modifiedValue = this.modifyValue(formattedValue)
      this.$emit('update:modelValue', modifiedValue)
      this.$emit('input', modifiedValue)
    },

    handleBlur(event) {
      const { value } = event.target
      const formattedValue = this.formatValue(value)
      const modifiedValue = this.modifyValue(formattedValue)
      this.localValue = modifiedValue // sync localValue
      this.$emit('blur', event)
    },
  },
}
</script>
